<template>
    <div class="relative">
        <div class="flex  items-center cursor-pointer">
            <span v-if="modelValue" class="text-primary mr-px flex items-center" @click.stop.prevent="uncheck()">
              <i :class="`fa-solid fa-square-check ${size === 'small' ? 'w-5 h-5' : 'w-6 h-6' }`"></i>
            </span>
            <p v-else class="text-gray3 mr-px flex items-center" @click.stop.prevent="check()">
              <i :class="`fa-regular fa-square ${size === 'small' ? 'w-5 h-5' : 'w-6 h-6' }`"></i>
            </p>
            <span v-if="modelValue" class=" pl-1  cursor-pointer" :class="isTextregular ? '' : 'label_css'" @click.stop.prevent="uncheck()" >{{labelText}}</span>
            <span v-else class=" pl-1 cursor-pointer" :class="isTextregular ? '' : 'label_css'" @click.stop.prevent="check()" >{{labelText}}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: [ 'checkValue', 'label',"disabled", "size", "isTextregular"],
  data() {
    return {
        labelText: '',
        disabledcheckbox: false,
        modelValue: false
    };
  },
  watch: {
    label: {
        handler () {
          this.labelText = this.label
        }
    },
    checkValue: {
        handler () {
          this.modelValue = this.checkValue
        }
    },
    disabled: {
        handler () {
          this.disabledcheckbox = this.disabled
        }
    },
  },
  mounted() {
    this.labelText = this.label
    this.modelValue = this.checkValue
    this.inputTypeText = this.inputType
    if (this.disabled) {
      this.disabledcheckbox = this.disabled
    }
  },
  methods: {
    uncheck () {
      if (!this.disabled) {
        this.modelValue = !this.modelValue
        this.$emit("setIsActive", this.modelValue)
      }
    },
    check () {
      if (!this.disabled) {
        this.modelValue = !this.modelValue
        this.$emit("setIsActive", this.modelValue)
      }
    },
  },
};
</script>
<style scoped>
</style>
