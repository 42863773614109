<template>
  <div class="mt-12">
    <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          Notes Categories ({{notesCategories.length}})
        </div>
      </div>
      <div class="mt-8">
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewCust(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Notes Category'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="notesCategories.length > 0">
          <draggable :list="notesCategories" group="people" @start="drag = true" @end="dragArray" id='list'>
              <div v-for="(data, index) in notesCategories" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openDetail(data.noteMasterCategoryId, data)">
                <div class="flex items-center justify-between">
                  <p class="text-gray4 heading-5" style="font-weight: bold;">
                    <i class="text-gray3 pr-4 fas fa-grip-vertical"></i>
                    {{data.categoryName}}
                    <span class="text-gray4 heading-6" style="font-weight: normal;" v-if="data.isDefault">(Is Default)</span>
                  </p>
                </div>
              </div>
          </draggable>
        </div>
        <div v-else>
          <NoRecords :alertMessage="`No records found.`" />
        </div>
      </div>
    </div>
    <add-edit-tax v-if="addJobCategory" :noteMasterCategoryId="selectedCatId" :noteCategData="selectedDetail" :fullArry="notesCategories" />
   </div>
</template>
<script>
import draggable from 'vuedraggable'
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
import AddEditTax from './addEditNoteCate.vue'
export default {
  components: {
    NoRecords,
    Button,
    AddEditTax,
    draggable,
  },
  data() {
    return {
      drag: false,
      notesCategories: [],
      searchForLead: '',
      addJobCategory: false,
      selectedCatId: 0,
      selectedDetail: {
        categoryId: 0,
        categoryName: '',
        isDefault: false
      }
    };
  },
  created() {},
  mounted() {
    document.body.style = 'overflow: visible;'
    this.getNoteCategoryList()
    this.$root.$on("addNewNoteCategoryComponennt", (response) => {
      console.log(response);
      if (response) {
        this.getNoteCategoryList()
        this.addJobCategory = false
      }
      this.addJobCategory = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addNewNoteCategoryComponennt')
  },
  methods: {
    openDetail (noteMasterCategoryId, data) {
      this.selectedCatId = noteMasterCategoryId
      this.selectedDetail.categoryId = data.noteMasterCategoryId
      this.selectedDetail.categoryName = data.categoryName
      this.selectedDetail.isDefault = data.isDefault
      this.addJobCategory= true
    },
    addNewCust () {
      this.selectedCatId = 0
      this.selectedDetail.categoryId = 0
      this.selectedDetail.categoryName = ''
      this.selectedDetail.isDefault = false
      this.addJobCategory= true
    },
    getNoteCategoryList () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetNotesCategories(
          response => {
            this.notesCategories = response.Data.categoryList !== null ? response.Data.categoryList : []
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    dragArray () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      this.drag = false
      MyJobApp.UpdateNoteCategoryOrder(
        this.notesCategories,
        response => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.getNoteCategoryList()
        },
        error => {
          this.getNoteCategoryList()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
};
</script>
<style scoped>
.lead_name_col {
  min-width: 280px !important;
  width: 280px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>