<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" id="itemdialogs" style="width: 600px;max-height: 85%;">
    <div class="relative bg-white">
      <div class="bg-primary flex justify-between p-3 sticky top-0">
        <div class=" font-medium text-white heading-3">
          {{noteMasterCategoryId > 0 ? 'Update Note Category' : 'Add New Note Category'}}
        </div>
        <div v-if="noteMasterCategoryId > 0">
          <div class=" text-error heading-5 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="getNoteCategoryList()"> 
            <i class="fa-solid fa-trash"></i>
          </div>
        </div>
      </div>
      <div style="max-height: 60vh" class="overflow-auto">
        <div class="p-4">
         <div class="mb-4">
              <TextField
              :inputId="'34324testInput'"
              :textMaxlength="50"
              :showcharLimit="true"
              :inputext="saveNoteCateData.categoryName"
              :placholderText="`Category Name`"
              :lableText="'Category Name'"
              :autoFocus="false"
              @inputChangeAction="(data) => saveNoteCateData.categoryName = data"  />
          </div>
         <div class="mb-4">
             <CheckBoxInput
              :checkValue="saveNoteCateData.isDefault"
              :label="`Set as Default ${secondLabel}`"
              @setIsActive="saveNoteCateData.isDefault = !saveNoteCateData.isDefault"  />
            </div>
            <!-- :disabled="!saveNoteCateData.isDefault ? false : true" -->
        </div>
      </div>
      <div class=" sticky -bottom-1 bg-white w-full flex justify-end pr-3 pt-3">
        <div class="text-rigth flex gap-2 mb-3">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" :disabled="saveNoteCateData.categoryName.trim() !== '' ? false : true" @buttonAction="SaveNoteCategoryDetail()"/>
        </div>
      </div>
    </div>
  </div>
  <div v-if="removeConfirm" class="popup_overlay px-4">
    <div class="custom_dialog_with_overflow" id="itemdialogs" style="width: 600px;max-height: 85%;">
      <div class="pt-2 pl-4">
        <p class="pa-0 ma-0 font-bold heading-3">Existing attachments assigne to</p>
        <p class="pa-0 ma-0 heading-5">Please select the category in which existing notes are to be assigned</p>
        </div>
      <div class="relative bg-white">
        <div>
          <div class="p-4">
          <div class="mb-4">
                <Dropdown
                  :disabled="false"
                  :inputext="searchForCategory" 
                  :inputId="'select_note_category'"
                  :lableText="'Select Note Category'"
                  :placholderText="`Select Note Category`"
                  :autoFocus="false"
                  :showArrow="true"
                  :setReadOnly="false"
                  :searchCallApi="true"
                  :showPlus="false"
                  :keyName="'categoryName'"
                  :listId="'noteMasterCategoryId'"
                  :items="notesCategories"
                  @selectNewForVal="selectedCategory"
                  />
          </div>
          </div>
        </div>
        <div class=" sticky -bottom-1 bg-white w-full flex justify-end pr-3 pt-3">
          <div class="text-rigth flex gap-2 mb-3">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="removeConfirm = false"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Ok'" :disabled="alternerNoteMasterCategoryId > 0 ? false : true" @buttonAction="noteCategoryDeleteCall()"/>
          </div>
        </div>
      </div>
  </div>
</div>
</div>
</template>
<script>
import Dropdown from '@/View/components/dropdown.vue'
import CheckBoxInput from '@/View/components/checkBoxInput.vue'
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
props: ['noteMasterCategoryId', 'noteCategData', 'fullArry'],
components: {
  Button,
  TextField,
  Dropdown,
  CheckBoxInput,
},
data () {
  return {
    secondLabel: '',
    removeConfirm: false,
    saveNoteCateData: {
      categoryId: 0,
      categoryName: '',
      isDefault: false
    },
    notesCategories: [],
    searchForCategory: '',
    alternerNoteMasterCategoryId: 0
  }
},
 created() {
},
mounted () {
  if (this.fullArry !== null) {
    for (let index = 0; index < this.fullArry.length; index++) {
      if (this.fullArry[index].isDefault) {
        this.secondLabel = `(Current Default is ${this.fullArry[index].categoryName})`
      }
    }
  }
  if (this.noteMasterCategoryId > 0) {
    if (this.noteCategData !== null) {
      this.saveNoteCateData.categoryId = this.noteCategData.categoryId
      this.saveNoteCateData.categoryName = this.noteCategData.categoryName
      this.saveNoteCateData.isDefault = this.noteCategData.isDefault
    }
  }
  document.body.style = 'overflow: hidden;'
},
watch: {},
methods: {
  discard () {
      document.body.style = 'overflow: visible;'
    this.$root.$emit('addNewNoteCategoryComponennt')
  },
  getNoteCategoryList () {
    this.removeConfirm = true
    MyJobApp.GetNotesCategories(
      response => {
        if (response.Data.categoryList !== null) {
          response.Data.categoryList.forEach(element => {
            if (element.noteMasterCategoryId !== this.noteMasterCategoryId)
            this.notesCategories.push(element)
          })
        } else {
          this.removeConfirm = false
        }
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      },
      () => {
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
    )
  },
  SaveNoteCategoryDetail () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.AddUpdateNoteCategory(
      this.saveNoteCateData.categoryId,
      this.saveNoteCateData.categoryName,
      this.saveNoteCateData.isDefault,
      response => {
        this.$root.$emit('addNewNoteCategoryComponennt', true)
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
      },
      () => {
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  },
  selectedCategory (data) {
    if (data !== null) {
      console.log('select note cate data', data)
      this.searchForCategory = data.categoryName
      this.alternerNoteMasterCategoryId = data.noteMasterCategoryId
    } else {
      this.searchForCategory = ''
      this.alternerNoteMasterCategoryId = 0
    }
  },
  noteCategoryDeleteCall () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.DeleteNoteCategory(
      this.noteMasterCategoryId,
      this.alternerNoteMasterCategoryId,
      response => {
        console.log('response', response);
        this.$root.$emit('addNewNoteCategoryComponennt', true)
        this.removeConfirm = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
      },
      () => {
        this.removeConfirm = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  }
},
beforeDestroy () {
  this.$root.$off('confirmBoxHandler')
}
}
</script>
<style scoped>

</style>